import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react"
import './ProjectCards.css'


type Description = {
    constructionName?: string;
    constructionLocation?: any;
    img?: any;
}

const ProjectCards: React.FC<Description> = ( { constructionName, constructionLocation, img } ) => {


    return (
        <IonCard className="ion-no-padding">
            <img alt="project" src={img} />
            <IonCardHeader>
                <IonCardTitle>{constructionName}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                {constructionLocation}
            </IonCardContent>
        </IonCard>
    )
}

export default ProjectCards;