import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import ProjectCards from '../components/ProjectCards';

// Import Swiper styles
import 'swiper/css';
import "swiper/css/bundle"

const SwiperComponent: React.FC = () => {
    return (
        <div className="swiper">
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                navigation={true}
                scrollbar={{ draggable: true }}
                spaceBetween={2}
                slidesPerView={1}
                
            >
                <SwiperSlide>
                    <ProjectCards
                        constructionName="Ponto do Sol"
                        constructionLocation="constructionLocation"
                        img="https://images.pexels.com/photos/396758/pexels-photo-396758.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    />
                </SwiperSlide>

                <SwiperSlide>
                    <ProjectCards
                        constructionName="constructionName"
                        constructionLocation="jaguaribe"
                        img="https://images.pexels.com/photos/323705/pexels-photo-323705.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    />
                </SwiperSlide>

                <SwiperSlide>
                    <ProjectCards
                        constructionName="constructionName"
                        constructionLocation="constructionLocation"
                        img="https://images.pexels.com/photos/323705/pexels-photo-323705.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    />
                </SwiperSlide>

                <SwiperSlide>
                    <ProjectCards
                        constructionName="constructionName"
                        constructionLocation="constructionLocation"
                        img="https://images.pexels.com/photos/323705/pexels-photo-323705.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    />
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default SwiperComponent;
