import { IonContent, IonHeader, IonPage, IonTitle, IonIcon, IonText, IonButton } from '@ionic/react';
import './Home.css';
import { logoInstagram } from 'ionicons/icons';
import PoliedroLogo from '../theme/PoliedroLogo.png';

import SwiperComponent from '../components/Swiper';

const Home: React.FC = () => {
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <div className="app-header">
          <div className="app-header-left">
            <IonTitle>Construtora Poliedro</IonTitle>
          </div>
          <div className="app-header-right">
            <a href="https://www.instagram.com/construtorapoliedro/">
              <IonIcon
                icon={logoInstagram}
                size="large"
                color="light"
              />
            </a>
          </div>
        </div>
      </IonHeader>
      <IonContent fullscreen>
        <div className="app-central-logo">
          <img src={PoliedroLogo} alt="logo" />
        </div>
        <div className="app-central-text">
          <h1>Poliedro Eng.</h1>
          <IonText>Lorem Ipsum is simply dok Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</IonText>
        </div>
        <IonButton href='https://www.instagram.com/construtorapoliedro/'>Saber Mais</IonButton>
        <SwiperComponent />
        <div className="footer">
          <p>©All Rights Reserved to Poliedro</p>
        </div>
      </IonContent>
    </IonPage >
  );
};

export default Home;
